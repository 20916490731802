@import "settings";

header.top-bar {
  position: relative;
  z-index: 10 !important;
  padding: 1.25rem;
  box-shadow: $shadowlight20;
  .grid-container {
    padding: 0;
    width: 100%;
  }
}

