@import "settings";

.hero{
    padding: 4rem 1rem 4rem;
    
    h1{
        margin-bottom: 1rem;
    }
    @include breakpoint(small only){
        padding: 3.5rem 1rem 4rem;
    }
    .hero__content{
        @include breakpoint(large){
            p{
                font-size: 18px;
            }
        }
    }
    .hero__img{
        margin-bottom: 2rem;
    }
}
