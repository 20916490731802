.footer{
    padding: 2rem 1rem;

    ul{
        list-style: none;
        margin: 0;
        li{
            margin-bottom: 1rem;
            a{
                color: $grey600;
                trandsiton: color 0.3s ease;
                &:hover{
                    color: $grey800;
                    text-decoration: underline;
                }
            }
        }
    }
}