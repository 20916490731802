@import "settings";

.button-group .button {
  @include breakpoint(small only) {
    margin-right: 0;
  }
  margin-bottom: 0rem;

  &:not(:first-child) {
    @include breakpoint(small only) {
      margin-top: 2rem;
    }
  }
  &:only-child {
    @include breakpoint(small only) {
      margin-top: 0;
    }
  }
}

.button {
  transition: all 0.3s ease;
  line-height: 16px;
  font-weight: 600;
  &:hover {
    &.animate-right {
      svg {
        transform: translateX(0.25rem);
      }
    }
    &.animate-left {
      svg {
        transform: translateX(-0.25rem);
      }
    }
  }
  &.icon-left {
    svg {
      margin-right: 0.5rem;
    }
  }
  &.icon-right {
    svg {
      margin-left: 0.5rem;
    }
  }
  &.chevron-up,
  &.chevron-down {
    svg {
      transform: translateY(-2px);
    }
  }
  &.primary {
    transition: all 0.3s ease;
    svg,
    path {
      fill: $white;
    }
    &.hollow {
      svg,
      path {
        fill: $forest2;
      }
      &:hover {
        svg,
        path {
          fill: $forest1;
        }
      }
    }
  }

  &.white {
    &.on-forest {
      color: $forest2;
      svg {
        fill: $forest2;
      }
    }
    &.on-coal {
      color: $coal2;
      svg {
        fill: $coal2;
      }
    }
  }
  &.transparent {
    transition: color 0.3s ease;
    background-color: transparent;
    border: transparent;
    color: $forest2;
    padding: 1rem 0;
    box-shadow: none !important;
    font-size: 18px;
    line-height: 16px;
    font-weight: 600;
    &.blue {
      color: $coal2;
    }
    &:disabled {
      &:hover {
        background-color: transparent;
      }
    }

    svg {
      fill: $forest2;
    }

    &:hover {
      color: $forest1;
      &.blue {
        color: $coal1;
      }
      svg {
        fill: $forest1;
      }
    }

    &.white {
      color: $white;
      background-color: transparent !important;
      &:hover {
        text-decoration: underline;
      }
      svg {
        fill: $white;
      }
      &.has__icon {
        text-decoration: none;
      }
    }
  }

  &.hollow {
    background-color: $white;
    &.no__text {
      border: none;
      &:hover {
        border: none;
      }
    }
    &:not(:disabled) {
      &:hover {
        background-color: $white;
        svg,
        svg path {
          fill: $forest1;
        }
      }
    }

    &.alternate {
      border-color: #c5d7e5;
    }

    svg,
    svg path {
      fill: $forest2;
    }
  }
  box-shadow: $shadowbutton;
  &:not(:disabled) {
    &:hover {
      box-shadow: $shadowbuttonhover;
    }
  }

  &:not(.no__text) {
    &.icon__position-right {
      svg {
        margin-left: 1rem;
      }
    }
    &.icon__position-left {
      svg {
        margin-right: 1rem;
      }
    }
  }

  svg {
    transition: transform 0.3s ease, fill 0.3s ease;
    path {
      transition: fill 0.3s ease;
    }
  }
  &.back__to-top {
    color: $primary;
    background-color: transparent;
    box-shadow: none !important;
    svg,
    path {
      fill: $forest2;
    }
    &:hover {
      color: $forest1;
      background-color: transparent;
      svg,
      path {
        fill: $forest1;
      }
    }
  }
}
