@import "settings";

.journey{
    padding: 0 1rem 9rem;
    @include breakpoint(small only){
        padding: 0 1rem 5rem;
    }
    p{
    margin-bottom: 0;
    @include breakpoint(large){
        font-size: 18px;
        }
    }
    .journey__content{
        @include breakpoint(large){
            h2{
                margin-bottom: 1rem;
            }
        }
    }
    .journey__tiles{
        margin-top: 2.5rem;
  
        .journey__tile{
            padding: 2rem;
            border: 1px solid rgba(200, 211, 224, 1);
            border-radius: 0.5rem;
            transition: box-shadow 0.3s ease;

            &:hover{
                box-shadow: 0px 4px 16px 0px rgba(54, 72, 92, 0.16);
            }
            @include breakpoint(small only){
                padding: 2rem 0.5rem;
                width: calc(50% - 8px);
            }
            .journey__icon{
                width: 3.5rem;
                height: 3.5rem;
                border-radius: 2.5rem;
                border: 1px solid #3971A8;
                background-color: #E6F1FC;
                margin: 0 auto 1.5rem auto;
            }
        }
        .as-h4{
            @include breakpoint(small only){
                font-size: 1rem;
            }
        }
    }
}