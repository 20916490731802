@import "settings";

.grid-containter{
  @include breakpoint(small only){
    // padding: 0 1rem;
  }
}
#questions {
  padding: 4rem 1rem;

  .columns{
    margin-bottom: 2rem;
    .content__column{
      padding: 2rem 1rem;
      .as-h5{
        margin-bottom: 1rem;;
      }
      @include breakpoint(large){
      padding: 2rem;
        p{
            font-size: 18px;
        }
      }
    }
  }
  .button{
    &:hover{
      background-color: transparent;
    }
  }

}