h1,
h2,
h3,
h4,
h5,
h6,
.as-h1,
.as-h2,
.as-h3,
.as-h4,
.as-h5 {
  color: $coal2;
}

.has-color.has-white-color {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  p a,
  .as-h1,
  .as-h2,
  .as-h3,
  .as-h4,
  .as-h5 {
    color: $white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
a {
  // color: inherit;
  &.as-h1 {
    font-size: 64px;
    line-height: 72px;
    @include breakpoint(small only) {
      font-size: 40px;
      line-height: 56px;
    }
  }
  &.as-h2 {
    font-size: 40px;
    line-height: 56px;
    @include breakpoint(small only) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  &.as-h3 {
    font-size: 32px;
    line-height: 44px;
    @include breakpoint(small only) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &.as-h4 {
    font-size: 24px;
    line-height: 32px;
    @include breakpoint(small only) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &.as-h5 {
    font-size: 20px;
    line-height: 28px;
    @include breakpoint(small only) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &.as-h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .has-text-color & {
    color: inherit;
  }
}
