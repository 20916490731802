@import "settings";

//font colouring
.has-text-color {
  h1,
  h2,
  h3,
  h4,
  h4,
  h5,
  h6 {
    color: inherit; // elliot wrote this
  }
  &.has-primary-color {
    color: $primary;
  }
  &.has-black-color {
    color: $black;
  }
  &.has-white-color {
    color: $white;
  }
  &.has-greenery-color {
    color: $greenery;
  }
  &.has-coal-1-color {
    color: $coal1;
  }
  &.has-coal-2-color {
    color: $coal2;
    p {
      color: $primary;
    }
  }
  &.has-coal-3-color {
    color: $coal3;
  }
  &.has-coal-4-color {
    color: $coal4;
  }
  &.has-coal-5-color {
    color: $coal5;
  }
  &.has-coal-6-color {
    color: $coal6;
  }
  &.has-forest-1-color {
    color: $forest1;
  }
  &.has-forest-2-color {
    color: $forest2;
  }
  &.has-forest-3-color {
    color: $forest3;
  }
  &.has-forest-4-color {
    color: $forest4;
  }
  &.has-forest-5-color {
    color: $forest5;
  }
  &.has-berry-1-color {
    color: $berry1;
  }
  &.has-berry-2-color {
    color: $berry2;
  }
  &.has-berry-3-color {
    color: $berry3;
  }
  &.has-berry-4-color {
    color: $berry4;
  }
  &.has-berry-5-color {
    color: $berry5;
  }
  &.has-zest-1-color {
    color: $zest1;
  }
  &.has-zest-2-color {
    color: $zest2;
  }
  &.has-zest-3-color {
    color: $zest3;
  }
  &.has-zest-4-color {
    color: $zest4;
  }
  &.has-zest-5-color {
    color: $zest5;
  }
  &.has-plum-1-color {
    color: $plum1;
  }
  &.has-plum-2-color {
    color: $plum2;
  }
  &.has-plum-3-color {
    color: $plum3;
  }
  &.has-plum-4-color {
    color: $plum4;
  }
  &.has-plum-5-color {
    color: $plum5;
  }
  &.has-cherry-1-color {
    color: $cherry1;
  }
  &.has-cherry-2-color {
    color: $cherry2;
  }
  &.has-cherry-3-color {
    color: $cherry3;
  }
  &.has-cherry-4-color {
    color: $cherry4;
  }
  &.has-cherry-5-color {
    color: $cherry5;
  }
  //new palette
  &.has-grey-100-color {
    color: $grey100;
  }
  &.has-grey-200-color {
    color: $grey200;
  }
  &.has-grey-300-color {
    color: $grey300;
  }
  &.has-grey-400-color {
    color: $grey400;
  }
  &.has-grey-500-color {
    color: $grey500;
  }
  &.has-grey-600-color {
    color: $grey600;
  }
  &.has-grey-700-color {
    color: $grey700;
  }
  &.has-grey-800-color {
    color: $grey800;
  }
  &.has-grey-900-color {
    color: $grey900;
  }
  &.has-green-100-color {
    color: $green100;
  }
  &.has-green-200-color {
    color: $green200;
  }
  &.has-green-300-color {
    color: $green300;
  }
  &.has-green-400-color {
    color: $green400;
  }
  &.has-green-500-color {
    color: $green500;
  }
  &.has-green-600-color {
    color: $green600;
  }
  &.has-green-700-color {
    color: $green700;
  }
  &.has-green-800-color {
    color: $green800;
  }
  &.has-green-900-color {
    color: $green900;
  }
  &.has-blue-100-color {
    color: $blue100;
  }
  &.has-blue-200-color {
    color: $blue200;
  }
  &.has-blue-300-color {
    color: $blue300;
  }
  &.has-blue-400-color {
    color: $blue400;
  }
  &.has-blue-500-color {
    color: $blue500;
  }
  &.has-blue-600-color {
    color: $blue600;
  }
  &.has-blue-700-color {
    color: $blue700;
  }
  &.has-blue-800-color {
    color: $blue800;
  }
  &.has-blue-900-color {
    color: $blue900;
  }
  &.has-red-100-color {
    color: $red100;
  }
  &.has-red-200-color {
    color: $red200;
  }
  &.has-red-300-color {
    color: $red300;
  }
  &.has-red-400-color {
    color: $red400;
  }
  &.has-red-500-color {
    color: $red500;
  }
  &.has-red-600-color {
    color: $red600;
  }
  &.has-red-700-color {
    color: $red700;
  }
  &.has-red-800-color {
    color: $red800;
  }
  &.has-red-900-color {
    color: $red900;
  }
  &.has-orange-100-color {
    color: $orange100;
  }
  &.has-orange-200-color {
    color: $orange200;
  }
  &.has-orange-300-color {
    color: $orange300;
  }
  &.has-orange-400-color {
    color: $orange400;
  }
  &.has-orange-500-color {
    color: $orange500;
  }
  &.has-orange-600-color {
    color: $orange600;
  }
  &.has-orange-700-color {
    color: $orange700;
  }
  &.has-orange-800-color {
    color: $orange800;
  }
  &.has-orange-900-color {
    color: $orange900;
  }
  &.has-yellow-100-color {
    color: $yellow100;
  }
  &.has-yellow-200-color {
    color: $yellow200;
  }
  &.has-yellow-300-color {
    color: $yellow300;
  }
  &.has-yellow-400-color {
    color: $yellow400;
  }
  &.has-yellow-500-color {
    color: $yellow500;
  }
  &.has-yellow-600-color {
    color: $yellow600;
  }
  &.has-yellow-700-color {
    color: $yellow700;
  }
  &.has-yellow-800-color {
    color: $yellow800;
  }
  &.has-yellow-900-color {
    color: $yellow900;
  }
}

.has-background {
  &.has-black-background-color {
    background-color: $black;
  }
  &.has-white-background-color {
    background-color: $white;
  }
  &.has-greenery-background-color {
    background-color: $greenery;
  }
  &.has-coal-1-background-color {
    background-color: $coal1;
  }
  &.has-coal-2-background-color {
    background-color: $coal2;
  }
  &.has-coal-3-background-color {
    background-color: $coal3;
  }
  &.has-coal-4-background-color {
    background-color: $coal4;
  }
  &.has-coal-5-background-color {
    background-color: $coal5;
  }
  &.has-coal-6-background-color {
    background-color: $coal6;
  }
  &.has-forest-1-background-color {
    background-color: $forest1;
  }
  &.has-forest-2-background-color {
    background-color: $forest2;
  }
  &.has-forest-3-background-color {
    background-color: $forest3;
  }
  &.has-forest-4-background-color {
    background-color: $forest4;
  }
  &.has-forest-5-background-color {
    background-color: $forest5;
  }
  &.has-berry-1-background-color {
    background-color: $berry1;
  }
  &.has-berry-2-background-color {
    background-color: $berry2;
  }
  &.has-berry-3-background-color {
    background-color: $berry3;
  }
  &.has-berry-4-background-color {
    background-color: $berry4;
  }
  &.has-berry-5-background-color {
    background-color: $berry5;
  }
  &.has-zest-1-background-color {
    background-color: $zest1;
  }
  &.has-zest-2-background-color {
    background-color: $zest2;
  }
  &.has-zest-3-background-color {
    background-color: $zest3;
  }
  &.has-zest-4-background-color {
    background-color: $zest4;
  }
  &.has-zest-5-background-color {
    background-color: $zest5;
  }
  &.has-plum-1-background-color {
    background-color: $plum1;
  }
  &.has-plum-2-background-color {
    background-color: $plum2;
  }
  &.has-plum-3-background-color {
    background-color: $plum3;
  }
  &.has-plum-4-background-color {
    background-color: $plum4;
  }
  &.has-plum-5-background-color {
    background-color: $plum5;
  }
  &.has-cherry-1-background-color {
    background-color: $cherry1;
  }
  &.has-cherry-2-background-color {
    background-color: $cherry2;
  }
  &.has-cherry-3-background-color {
    background-color: $cherry3;
  }
  &.has-cherry-4-background-color {
    background-color: $cherry4;
  }
  &.has-cherry-5-background-color {
    background-color: $cherry5;
  }
  //new palette
  &.has-grey-100-background-color {
    background-color: $grey100;
  }
  &.has-grey-200-background-color {
    background-color: $grey200;
  }
  &.has-grey-300-background-color {
    background-color: $grey300;
  }
  &.has-grey-400-background-color {
    background-color: $grey400;
  }
  &.has-grey-500-background-color {
    background-color: $grey500;
  }
  &.has-grey-600-background-color {
    background-color: $grey600;
  }
  &.has-grey-700-background-color {
    background-color: $grey700;
  }
  &.has-grey-800-background-color {
    background-color: $grey800;
  }
  &.has-grey-900-background-color {
    background-color: $grey900;
  }
  &.has-green-100-background-color {
    background-color: $green100;
  }
  &.has-green-200-background-color {
    background-color: $green200;
  }
  &.has-green-300-background-color {
    background-color: $green300;
  }
  &.has-green-400-background-color {
    background-color: $green400;
  }
  &.has-green-500-background-color {
    background-color: $green500;
  }
  &.has-green-600-background-color {
    background-color: $green600;
  }
  &.has-green-700-background-color {
    background-color: $green700;
  }
  &.has-green-800-background-color {
    background-color: $green800;
  }
  &.has-green-900-background-color {
    background-color: $green900;
  }
  &.has-blue-100-background-color {
    background-color: $blue100;
  }
  &.has-blue-200-background-color {
    background-color: $blue200;
  }
  &.has-blue-300-background-color {
    background-color: $blue300;
  }
  &.has-blue-400-background-color {
    background-color: $blue400;
  }
  &.has-blue-500-background-color {
    background-color: $blue500;
  }
  &.has-blue-600-background-color {
    background-color: $blue600;
  }
  &.has-blue-700-background-color {
    background-color: $blue700;
  }
  &.has-blue-800-background-color {
    background-color: $blue800;
  }
  &.has-blue-900-background-color {
    background-color: $blue900;
  }
  &.has-red-100-background-color {
    background-color: $red100;
  }
  &.has-red-200-background-color {
    background-color: $red200;
  }
  &.has-red-300-background-color {
    background-color: $red300;
  }
  &.has-red-400-background-color {
    background-color: $red400;
  }
  &.has-red-500-background-color {
    background-color: $red500;
  }
  &.has-red-600-background-color {
    background-color: $red600;
  }
  &.has-red-700-background-color {
    background-color: $red700;
  }
  &.has-red-800-background-color {
    background-color: $red800;
  }
  &.has-red-900-background-color {
    background-color: $red900;
  }
  &.has-orange-100-background-color {
    background-color: $orange100;
  }
  &.has-orange-200-background-color {
    background-color: $orange200;
  }
  &.has-orange-300-background-color {
    background-color: $orange300;
  }
  &.has-orange-400-background-color {
    background-color: $orange400;
  }
  &.has-orange-500-background-color {
    background-color: $orange500;
  }
  &.has-orange-600-background-color {
    background-color: $orange600;
  }
  &.has-orange-700-background-color {
    background-color: $orange700;
  }
  &.has-orange-800-background-color {
    background-color: $orange800;
  }
  &.has-orange-900-background-color {
    background-color: $orange900;
  }
  &.has-yellow-100-background-color {
    background-color: $yellow100;
  }
  &.has-yellow-200-background-color {
    background-color: $yellow200;
  }
  &.has-yellow-300-background-color {
    background-color: $yellow300;
  }
  &.has-yellow-400-background-color {
    background-color: $yellow400;
  }
  &.has-yellow-500-background-color {
    background-color: $yellow500;
  }
  &.has-yellow-600-background-color {
    background-color: $yellow600;
  }
  &.has-yellow-700-background-color {
    background-color: $yellow700;
  }
  &.has-yellow-800-background-color {
    background-color: $yellow800;
  }
  &.has-yellow-900-background-color {
    background-color: $yellow900;
  }
  &.medium-background-transparent {
    @include breakpoint(medium down) {
      background-color: transparent;
    }
  }
  &.small-background-transparent {
    @include breakpoint(small only) {
      background-color: transparent;
    }
  }
}
